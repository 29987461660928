import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import logo from '../assets/logo2.png';
import profileUser from '../assets/profile-user.png';
import { useAuth } from '../hooks/useAuth';
import '../css/BulkChatStyle.css';
import {db} from "../firebase";
import {doc, deleteDoc,setDoc, getDoc} from "firebase/firestore";

const Chat = () => {
    const { currentUser } = useAuth();
    const user_id = currentUser.uid;
    let tempMsgId = null;
    const chatEndRef = useRef(null);
    const [message, setMessage] = useState('');

    const [maxLength, setMaxLength] = useState(150);
    const [maxK, setMaxK] = useState(2);
    const [dayNumber, setDayNumber] = useState('');
    const [token, setToken] = useState('');
    const [tokenLoaded, setTokenLoaded] = useState(false);
    const [userName, setUserName] = useState('');
    const [personaNumber, setPersonaNumber] = useState('');
    const [userHistory, setUserHistory] = useState('');
    const [personaText, setPersonaText] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [historyLoading, setHistoryLoading] = useState(false);
    const [historyGenerated, setHistoryGenerated] = useState(false);
    const [assistantStep, setAssistantStep] = useState('');
    const [historyVerified, setHistoryVerified] = useState(false);

    const [closingDay, setClosingDay] = useState(false);
    const [savingDay, setSavingDay] = useState(false);

    const [conversation, setConversation] = useState([]);
    const textareaRef = useRef(null);
    const editTextRef = useRef(null);
    const [editText, setEditText] = useState('');
    const [editingMessageId, setEditingMessageId] = useState(null);

    const baseUrl="https://app.metamindful.ai";
    const historyUrl="https://app.metamindful.ai:4000";
    const api_token="915e7211-e8dd-4d96-842e-361a8993a546";
   // const baseUrl="";

    useEffect(() => {
        // Construct the URL
        const url = `${historyUrl}/generate_history_single?day_number=1&name=Maaz`;

        // Define the API call function
        const fetchData = async () => {
            try {
                const response = await axios.post(url);
                console.log(response.data); // Save API response to state
            } catch (err) {
                console.log(err.message); // Handle error
            } finally {
            }
        };

        // Call API only if parameters are available
        if (historyUrl ) {
            fetchData();
        }
    }, [historyUrl]); // Re-run effect if any dependency changes
    useEffect(() => {
        // Construct the URL
        const url = `https://app.metamindful.ai/health`;

        // Define the API call function
        const fetchData = async () => {
            try {
                const response = await axios.get(url);
                console.log(response.data); // Save API response to state
            } catch (err) {
                console.log(err.message); // Handle error
            } finally {
            }
        };

        // Call API only if parameters are available
        fetchData();
    }, []); // Re-run effect if any dependency changes
    useEffect(() => {
        // Construct the URL
        const url = `https://app.metamindful.ai/health1`;

        // Define the API call function
        const fetchData = async () => {
            try {
                const response = await axios.post(url);
                console.log(response.data); // Save API response to state
            } catch (err) {
                console.log(err.message); // Handle error
            } finally {
            }
        };

        // Call API only if parameters are available
        fetchData();
    }, []); // Re-run effect if any dependency changes

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set new height
        }
    }, [personaText,assistantStep]); // Runs whenever personaText updates
    useEffect(() => {
        if (editTextRef.current) {
            editTextRef.current.style.height = "auto"; // Reset height
            editTextRef.current.style.height = `${editTextRef.current.scrollHeight}px`; // Set new height
        }
    }, [editText]);

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const response = await axios.post(`${baseUrl}/generate-token`,{
                        "user_id": user_id
                    },
                {
                    headers: {
                        "Content-Type":'application/json',
                        Authorization: `Bearer ${api_token}`
                    }
                });
                if(response.data.access_token){
                console.log(response.data.access_token);
                setToken(response.data.access_token);
                    setTokenLoaded(true);

                }else{
                console.error("Failed to generate token:", error);
                setError("❌ Failed to generate token. Please refresh.");
                }
            } catch (error) {
                console.error("Failed to generate token:", error);
                setError("❌ Failed to generate token. Please refresh.");
            }
        };

        fetchToken();
    }, []);
    // Handle double-click to start editing
    const handleEdit = (id, content) => {
        setEditingMessageId(id);
        setEditText(content);
    };
    const handleSubmitWorking = async (e) => {
        e.preventDefault();
        if (!message.trim()) return;

        // Create a new user message
        const newUserMessage = {
            id: new Date().getTime().toString(), // simple unique id; consider a better approach for production
            role: 'user',
            content: message
        };

        // Update the conversation with the new user message immediately
        const updatedConversation = [...conversation, newUserMessage];
        setConversation(updatedConversation);
        setSuccess('');
        setMessage('');
        setError('');

        try {
            // Prepare the conversation payload (only the role and content fields)

            const conversationPayload = updatedConversation.map(({ role, content }) => ({ role, content }));

            console.log(conversationPayload);
            const payload = {
                "prompt": conversationPayload,
                "priority": 1,
                "stream": true, // Send only the relevant user prompt
                "max_tokens": 20
            };
            const url = `${baseUrl}/protected-endpoint`;

            // Make API call with the conversation as the request body
            const response = await axios.post(url,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": `Bearer ${token}`

                    }
                }
            );

            const conversationFromResponse = response.data.conversation;
            const lastAssistantMsg = conversationFromResponse
                .filter(msg => msg.role === 'assistant')
                .pop();
            if (lastAssistantMsg) {
                const newAssistantMessage = {
                    id: (new Date().getTime() + 1).toString(),
                    role: 'assistant',
                    content: lastAssistantMsg.content
                };

                // Append the assistant's reply to the conversation
                setConversation(prevConversation => [...prevConversation, newAssistantMessage]);
            }

        } catch (error) {
            setError("❌ Failed to send message. Please try again.");
            console.error("Error sending message:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!message.trim()) return;

        // 1. Create and add the new user message
        const newUserMessage = {
            id: new Date().getTime().toString(),
            role: 'user',
            content: message,
        };

        const updatedConversation = [...conversation, newUserMessage];
        setConversation(updatedConversation);
        setMessage('');
        setError('');
        setSuccess('');

        // 2. Prepare the conversation payload (only role and content)
        const conversationPayload = updatedConversation.map(({ role, content }) => ({
            role,
            content,
        }));

        // 3. Always create a new assistant message for the new user input.
        const newAssistantId = new Date().getTime().toString();
        setConversation((prev) => [
            ...prev,
            { id: newAssistantId, role: 'assistant', content: '' },
        ]);
        // Use the new assistant message's id for updating its content
        const tempMsgId = newAssistantId;
        console.log("New Assistant Msg Id:", tempMsgId);

        // 4. Build the payload for the POST request.
        const payload = {
            prompt: conversationPayload,
            priority: 1,
            stream: true, // Request a streaming response
            max_tokens: 20,
        };

        const url = `${baseUrl}/protected-endpoint`;

        try {
            // 5. Make a POST request using fetch. The server returns a streaming response.
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/event-stream; charset=utf-8',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok || !response.body) {
                throw new Error('Network response was not ok or streaming not supported');
            }

            // 6. Process the streamed response.
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let buffer = '';
            let done = false;

            console.log("Starting to read stream...");

            while (!done) {
                const { done: doneReading, value } = await reader.read();
                if (doneReading) break;

                // Decode the chunk and add it to the buffer.
                const chunk = decoder.decode(value, { stream: true });
                console.log("Raw chunk:", chunk);
                buffer += chunk;

                // Split the buffer into lines.
                const lines = buffer.split('\n');
                // Keep the incomplete part in the buffer.
                buffer = lines.pop();

                // Process each complete line.
                for (let line of lines) {
                    line = line.trim();
                    if (!line) continue;

                    // Remove the "data:" prefix.
                    if (line.startsWith("data:")) {
                        line = line.slice(5).trim();
                    }

                    try {
                        const parsed = JSON.parse(line);
                        console.log("Parsed chunk:", parsed);

                        // If there's text, append it to the new assistant's message.
                        if (parsed.text) {
                            setConversation((prev) =>
                                prev.map((msg) =>
                                    msg.id === tempMsgId
                                        ? { ...msg, content: msg.content + parsed.text }
                                        : msg
                                )
                            );
                        }

                        // Stop reading if the stream signals completion.
                        if (parsed.isCompleted && parsed.isCompleted === "true") {
                            console.log("Stream signaled completion.");
                            done = true;
                            break;
                        }
                    } catch (err) {
                        console.error("Error parsing JSON chunk:", err, "Line:", line);
                    }
                }
            }
        } catch (error) {
            console.error("Error during streaming fetch:", error);
            setError("❌ Failed to send message. Please try again.");
        }
    };
    const handleInputChange = (e) => {
        setMessage(e.target.value);
        textareaRef.current.style.height = "auto";
        const scrollHeight = textareaRef.current.scrollHeight;
        const height = Math.min(scrollHeight, 10 * 30);
        textareaRef.current.style.height = height < 60 ? `60px` : `${height}px`;
    };
    // Save edited message
    const handleSaveEdit = () => {
        setConversation(prev =>
            prev.map(msg =>
                msg.id === editingMessageId ? { ...msg, content: editText } : msg
            )
        );
        setEditingMessageId(null);
        setEditText('');
    };

    // Cancel editing
    const handleCancelEdit = () => {
        setEditingMessageId(null);
        setEditText('');
    };

    const closeDay = async (e) => {
        e.preventDefault();
        setMessage('');
        setSuccess('');
        setError('');
        setClosingDay(true);

        try {
            const conversationFilter = conversation.map(({ role, content }) => ({ role, content }));

            const url=`${baseUrl}/save_single?day_number=${dayNumber}&name=${userName}`;
            const response = await axios.post(url, conversationFilter, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });

            const { status } = response.data;

            // Handle success
            if (status === "success") {
                const docId = `chat_${dayNumber}_${userName}`;
                const docRef = doc(db, "saved_chats", docId);

                await deleteDoc(docRef);
                setSuccess("Day Closed");
            } else {
                setError("Failed to save conversation history. Please try again.");

            }

            setClosingDay(false);

        } catch (error) {
            setClosingDay(false);

            setError("Failed to save conversation history. Please try again.");
        }
    };
    const saveDay = async (e) => {
        e.preventDefault();
        setSuccess('');
        setMessage('');
        setSavingDay(true);

        try {
            const conversationFilter = conversation.map(({ role, content }) => ({ role, content }));

            const dataToSend = {
                "day_number": dayNumber,
                "name": userName,
                "conversation": conversationFilter,
                "user_id": user_id,
                "timestamp": new Date().toISOString()
            };

            const docId = `chat_${dayNumber}_${userName}`;

            // Reference to Firestore document inside "saved_chats"
            const docRef = doc(db, "saved_chats", docId);

            // Update existing document or insert if it doesn’t exist
            await setDoc(docRef, dataToSend, { merge: true });

            setSuccess("✅ Day Saved Successfully");
            setMessage('');
            setSavingDay(false);

        } catch (error) {
            setError("❌ Failed to save conversation history. Please try again.");
            setSavingDay(false);
        }
    };


    const handleGenerateHistory = async (e) => {
        e.preventDefault();
        setError(null);
        setHistoryLoading(true);

        if (!dayNumber || !userName) {
            setError("Day Number and Name are required.");
            setHistoryLoading(false);
            return;
        }

        try {
            const docId = `chat_${dayNumber}_${userName}`;
            const docRef = doc(db, "saved_chats", docId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists() && !historyGenerated) {
                // Load saved history from Firestore
                const savedData = docSnap.data();

                setPersonaText(savedData.persona || "");
                setUserHistory(savedData.user_history || "");
                setConversation(savedData.conversation || []);
                setHistoryGenerated(true);
                setHistoryVerified(false); // Mark as verified since it's saved

                setMessage("✅ Loaded Saved History");

            } else {
                // No saved history found, call API to generate new history
                const url = `${historyUrl}/generate_history_single?day_number=${dayNumber}&name=${userName}`;
                const response = await axios.post(url);
                const responseData = response.data;

                setUserHistory(responseData.current_day_history_with_user);

                const chatData = responseData.conversation.map((msg, index) => ({
                    id: index + 100,
                    role: msg.role,
                    content: msg.content.split("(MetaMindful):").pop().trim(),
                }));
                setConversation(chatData);
                setHistoryGenerated(true);
                setHistoryVerified(false); // Reset verification when regenerating
            }

        } catch (error) {
            setHistoryGenerated(true);
            setHistoryVerified(false);
            setError("❌ Failed to fetch conversation history. Please try again.");
        }
        /*finally {
            var newChat=[
                {
                    "role": "system",
                    "content": "You are MetaMindful, a world-class AI-based mindfulness coach. Your approach to mindfulness is evidence-based and ACT-based. In your approach, mindfulness is a set of four skills, which are ACT's four mindfulness core processes. This is your first session with this particular mindfulness user who has come to our app for mindfulness training. In this first session, you'll get to know the user and set a behavioral goal with them (specific actions that they want to be able to do more of despite certain emotions, urges, or impulses) and then link their behavioral goal to getting caught up in their thoughts and feelings and then introduce the first skill of defusion as a solution."
                },
                {
                    "role": "assistant",
                    "content": " Hello, welcome to MetaMindful! How are you feeling today?"
                }
            ];
            setConversation(newChat);
            setHistoryGenerated(true);
            setHistoryVerified(false);
        }*/

        setHistoryLoading(false);
    };
    const handleVerifyHistory = () => {
        setHistoryVerified(true);
        setError("✅ History Verified Successfully!");
    };




    return (
        <>
            <div className="chat__container">
                {!tokenLoaded && (
                    <>
                        {error && <p className="alert alert-danger">{error}</p>}
                        {!error &&<p className="alert alert-danger">Generating Token</p>}
                    </>
                )}
                {tokenLoaded &&
                <div className="chat__wrapper">
                    {historyVerified &&
                        <div  className="search-form">
                            <div className="d-flex justify-content-between mt-1">
                                <button className="btn btn-info btn-lg" disabled={closingDay} onClick={(e) => {closeDay(e)}}>

                                    {closingDay ? (
                                        <>
                                            Saving...
                                            <i className="fa fa-spinner fa-spin ml-2"></i>
                                        </>) : "Close Day"}
                                </button>
                                <button className="btn btn-success btn-lg" disabled={savingDay}  onClick={(e) => {saveDay(e)}}>
                                    {savingDay ? (
                                        <>
                                            Saving...
                                            <i className="fa fa-spinner fa-spin ml-2"></i>
                                        </>) : "Save Day"}
                                </button>
                            </div>
                        </div>
                    }
                    {!historyVerified && (
                        <form className="search-form d-flex justify-content-between" onSubmit={handleGenerateHistory}>
                            <div className="form-group">
                                <label htmlFor="day_number" className="form-label">Day Number *</label>
                                <input type="text" id="day_number" className="form-control"
                                       placeholder="Enter Day Number"
                                       value={dayNumber}
                                       onChange={(e) => setDayNumber(e.target.value)}
                                       min="1"
                                       max="100" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="day_number" className="form-label">User Name *</label>
                                <input type="text" id="user_name" className="form-control"
                                       placeholder="Enter User Name"
                                       value={userName}
                                       onChange={(e) => setUserName(e.target.value)}
                                      />
                            </div>


                            <button type="submit" className="btn btn-info btn-lg"
                                    disabled={historyLoading}
                                    style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                {historyLoading ? (
                                    <>Generating... <i className="fa fa-spinner fa-spin ml-2"></i></>
                                ) : (
                                    historyGenerated ? "Regenerate History" : "Generate History"
                                )}
                            </button>
                        </form>
                    )}
                    {historyGenerated && !historyVerified && (
                        <button className="btn btn-success mt-2" onClick={handleVerifyHistory}>
                            ✅ Verify History
                        </button>
                    )}


                    <div className="question_answer__wrapper__chatbot">
                        {error && <p className="alert alert-danger">{error}</p>}
                        {success && <p className="alert alert-success">{success}</p>}
                        {conversation.map((msg) => (

                            <div
                                key={msg.id}
                                className={`single__question__answer message ${msg.sender}`}
                                onDoubleClick={() => handleEdit(msg.id, msg.content)}
                            >
                                {msg.role === 'user' ? (
                                    <div className="question_user">

                                        <div className="left_user_info" style={{ color: 'black',cursor: 'pointer',width:'100%'  }}>
                                            <img
                                                style={{ width: '35px' }}
                                                src={profileUser}
                                                alt="avatar"
                                            />
                                            {editingMessageId === msg.id ? (
                                                <div className="edit-mode"  style={{width:'100%'}}>
                                                 <textarea
                                                     className="editable_text"
                                                     style={{width:'100%'}}
                                                     value={editText}
                                                     ref={editTextRef}
                                                     onChange={(e) => setEditText(e.target.value)}
                                                 ></textarea>

                                                    <button className="btn btn-danger " onClick={handleCancelEdit}><i className="fa fa-close"></i></button>
                                                    <button className="btn btn-success " onClick={handleSaveEdit}><i className="fa fa-check"></i></button>
                                                </div>
                                            ) : (
                                                <>
                                                    You: {msg.content}

                                                </>
                                            )}

                                        </div>
                                    </div>
                                ) : msg.role === 'assistant' ?(
                                    <div className="answer__area"  style={{width:'100%'}}>
                                        <div className="thumbnail"  style={{marginTop:'10px' }}>
                                            <img
                                                style={{ width: '30px !important'}}
                                                src={logo}
                                                alt="avatar"
                                            />
                                        </div>
                                        {editingMessageId === msg.id ? (
                                            <div className="edit-mode"  style={{width:'100%'}}>
                                                 <textarea
                                                     className="editable_text"
                                                     style={{width:'100%'}}
                                                     value={editText}
                                                     ref={editTextRef}
                                                     onChange={(e) => setEditText(e.target.value)}
                                                 ></textarea>
                                                <button className="btn btn-danger btn-sm" onClick={handleCancelEdit}><i className="fa fa-close"></i></button>
                                                <button className="btn btn-success btn-sm" onClick={handleSaveEdit}><i className="fa fa-check"></i></button>
                                            </div>
                                        ) : (
                                            <div  className="answer_main__wrapper" >

                                                <p className="disc">
                                                    {msg.content}

                                                </p>

                                            </div>
                                        )}
                                    </div>
                                ):(
                                    <div className="answer__area"  style={{width:'100%'}}>


                                        <div  className="answer_main__wrapper" >

                                            <p className="disc">
                                                System Message: {msg.content}

                                            </p>

                                        </div>

                                    </div>

                                )}
                            </div>
                        ))}

                    </div>
                    {error && <p className="alert alert-danger">{error}</p>}
                    {success && <p className="alert alert-success">{success}</p>}
                    {historyVerified ? (
                    <form onSubmit={handleSubmit} className="search-formm">
                        <div className="mb-3" style={{ position: 'relative' }}>
                            <textarea
                                ref={textareaRef}
                                className="form-control chat_area"

                                id="messageTextarea"
                                placeholder="Ask here"
                                rows="1"
                                value={message}
                                onChange={handleInputChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault(); // Prevent default newline behavior
                                        handleSubmit(e);
                                    }
                                }}
                            ></textarea>
                            <button
                                type="submit"
                                className="send-button"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    width: '40px',
                                    right: '10px',
                                    transform: 'translateY(-50%)',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    cursor: 'pointer',
                                    fontSize: '2.5rem',
                                    color: message.trim() ? 'black' : '#D7D7D7',
                                }}
                                disabled={!message.trim()}
                            >
                                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                            </button>
                        </div>
                    </form>
                        ):
                        (<><p className="alert alert-danger">First Generate and verify history</p></>)}

                </div>
                }
            </div>
        </>
    );
};

export default Chat;
